import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Spin from "./components/page/index.tsx";


const Main = () => {
	const routes = ["/", "/home1", "/home2"];
	return (
		<>
			
			<Router>
				<Routes>
					{routes.map((path) => (
						<Route path={path} element={<Spin />} />
					))}
				</Routes>
			</Router>
		</>
	);
};

export default Main;
